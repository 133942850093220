import React, {useEffect} from 'react';
import classes from './Home.module.css';
import textData from '../../text.json';
import profilePicture from '../../assets/about/elso2.jpg';
import img1 from '../../assets/home/img17.JPG';
import img11 from '../../assets/home/img24.jpg';
import img12 from '../../assets/home/img35.jpg';
import img13 from '../../assets/home/img34.jpg';
import img14 from '../../assets/home/img40.jpg';
import img2 from '../../assets/home/img26.JPG';
import img3 from '../../assets/home/img31.JPG';
import ApplyButton from '../components/ApplyButton';

const Home = () => {
    return (
        <section className={classes.mainContainer}>
            <div className={classes.parallaxContainer}>
                <p className={classes.intro}>{textData.home.intro}</p>
            </div>
            <div className={classes.titleBox}>
                <p className={classes.intro2}>{textData.home.intro2}</p>
                <p className={classes.intro3}>{textData.home.intro3}</p>
            </div>
            <div className={classes.aboutContainer}>
                <div className={classes.aboutTextContainer}>
                    <p className={classes.text}>{textData.home.aboutPara1}</p>
                    <p className={classes.text}>{textData.home.aboutPara2}</p>
                    <p className={classes.text}>{textData.home.aboutPara3}</p>
                </div>
                <div className={classes.aboutImageContainer}>
                    <img src={profilePicture} className={classes.profilePicture} loading="lazy"/>
                </div>
            </div>   
            <div className={classes.container}>
                <div className={classes.imageBox}>
                    <img src={img1} className={classes.image} loading="lazy"/>
                    <img src={img2} className={classes.image} loading="lazy"/>
                    <img src={img3} className={classes.image} loading="lazy"/>
                </div>
                <div className={classes.textBox}>
                    <p className={classes.title}>{textData.home.para1Title}</p>
                    <p className={classes.text}>{textData.home.para1Text}</p>
                    <p className={classes.text}>{textData.home.para11Text}</p>
                    <p className={classes.text}>{textData.home.para12Text}</p>
                    <p className={classes.title}>{textData.home.para2Title}</p>
                    <p className={classes.text}>{textData.home.para21Text}</p>
                    <p className={classes.text}>{textData.home.para22Text}</p>
                    <p className={classes.text}>{textData.home.para23Text}</p>
                    <p className={classes.title}>{textData.home.para3Title}</p>
                    <p className={classes.text}>{textData.home.para3Text}</p>
                    <p className={classes.text}>{textData.home.para31Text}</p>
                    <p className={classes.text}>{textData.home.para32Text}</p>
                    <p className={classes.text}>{textData.home.para33Text}</p>
                    <p className={classes.title}>{textData.home.para4Title}</p>
                    <p className={classes.text}>{textData.home.para4Text}</p>
                </div>                
            </div>
            <div className={classes.orderContainer}>
                <div className={classes.imageContainer}>
                    <img src={img11} className={classes.images} loading="lazy"/>
                    <img src={img12} className={classes.images} loading="lazy"/>
                    <img src={img13} className={classes.images} loading="lazy"/>
                    <img src={img14} className={classes.images} loading="lazy"/>
                </div>
                <div className={classes.orderBox}>
                    <div className={classes.orderTitleBox}>
                        <p className={classes.orderTitle}>{textData.home.orderTitle}</p>
                    </div>
                    <div className={classes.orderTextBox}>
                        <div className={classes.orderTextSubBox}>
                            <p className={classes.orderSubTitle}>1. lépés</p>
                            <p className={classes.orderSubTitle}>{textData.home.orderSubTitle1}</p>
                            <p className={classes.orderText}>{textData.home.orderText1}</p>
                        </div>
                        <div className={classes.orderTextSubBox}>
                            <p className={classes.orderSubTitle}>2.lépés</p>
                            <p className={classes.orderSubTitle}>{textData.home.orderSubTitle2}</p>
                            <p className={classes.orderText}>{textData.home.orderText2}</p>
                        </div>
                    </div>
                    <div className={classes.buttonBox}>
                        <ApplyButton text="Kérd ajánlatomat" />
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Home;