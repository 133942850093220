import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ApplyButton.module.css'; // A stílusok importálása

const ApplyButton = ({ text }) => {
    const navigate = useNavigate(); // React Router navigáció

    const handleButtonClick = () => {
        navigate('/apply'); // Az "/apply" útvonalra navigál
    };

    return (
        <button className={classes.button} onClick={handleButtonClick}>
            {text}
        </button>
    );
};

export default ApplyButton;
