import React from 'react';
import classes from './ApplyWorkshop.module.css';
import sampleImage from '../../assets/applyWorkshop/IMG_6855.JPG'; 
import ContactForm from './ContactForm';

const ApplyWorkshop = () => {
    return (
        <div className={classes.container}>
            <div className={classes.imageContainer}>
                <img src={sampleImage} alt="" className={classes.image} loading="lazy"/>
            </div>
            <div className={classes.contactFormContainer}>
                <ContactForm />
            </div>
        </div>
    );
};

export default ApplyWorkshop;
