import React, {useState} from 'react';
import { NavLink } from "react-router-dom";
import { Link } from 'react-scroll';
import classes from './Navbar.module.css';
import menu from '../../assets/menu.png';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <header className={classes.header}>
            <div></div>
            <nav className={classes.desktopMenu}>
                <ul className={classes.list}>
                    <li>
                        <NavLink to='/' className={({ isActive }) => isActive ? classes.active : undefined} end>Falkészítés</NavLink>
                    </li>
                    <li>
                        <NavLink to='/workshop' className={({ isActive }) => isActive ? classes.active : undefined}>Workshop</NavLink>
                    </li>
                    <li>
                        <NavLink to='/gallery' className={({ isActive }) => isActive ? classes.active : undefined}>Galéria</NavLink>
                    </li>
                    <li>
                        <NavLink to='/apply' className={({ isActive }) => isActive ? classes.active : undefined}>Ajánlatkérés</NavLink>
                    </li>
                </ul>
            </nav>
            <img src={menu} alt="Menu" className={classes.mobMenu} onClick={() => setShowMenu(!showMenu)}/>
            <div className={classes.navMenu} style={{display: showMenu ? 'flex' : 'none'}}>
                <NavLink to='/'
                      className={classes.listItem} onClick={() => setShowMenu(false)}>Falkészítés</NavLink>
                <NavLink to='/workshop'
                      className={classes.listItem} onClick={() => setShowMenu(false)}>Workshop</NavLink>
                <NavLink to='/gallery'
                      className={classes.listItem} onClick={() => setShowMenu(false)}>Galéria</NavLink>
                <NavLink to='/apply'
                      className={classes.listItem} onClick={() => setShowMenu(false)}>Ajánlatkérés</NavLink>
            </div>
        </header>
    );
};

export default Navbar;