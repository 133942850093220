import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // React Router hook
import classes from './ScrollToTop.module.css';

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);
    const { pathname } = useLocation(); // Figyeli az aktuális útvonalat

    useEffect(() => {
        // Navigáció után automatikusan a lap tetejére görget
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Sima görgetés
        });
    }, [pathname]); // Útvonal változáskor lefut

    useEffect(() => {
        const handleScroll = () => {
            // Ha a görgetés meghaladja a 300px-t, a gomb megjelenik
            setShowButton(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll); // Esemény hozzáadása
        return () => {
            window.removeEventListener('scroll', handleScroll); // Esemény eltávolítása
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Sima görgetés az oldal tetejére
        });
    };

    return (
        <>
            {showButton && (
                <button className={classes.scrollToTopButton} onClick={scrollToTop}>
                    ↑
                </button>
            )}
        </>
    );
};

export default ScrollToTop;
