import React, { useState, useEffect } from 'react';
import images from './galleryImages';
import classes from './Gallery.module.css';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    // ESC gomb kezelés
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setSelectedImage(null); // Bezárja a lightboxot
            }
        };

        window.addEventListener('keydown', handleKeyDown); // Esemény figyelése
        return () => {
            window.removeEventListener('keydown', handleKeyDown); // Esemény eltávolítása a komponens törlésekor
        };
    }, []); // Ez a hook egyszer fut le, amikor a komponens betöltődik

    const handleImageClick = (src) => {
        setSelectedImage(src);
    };

    const handleClose = (e) => {
        e.stopPropagation(); // Megakadályozza az esemény továbbterjedését
        setSelectedImage(null);
    };

    return (
        <div>
            <div className={classes.container}>
                {images.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Image ${index}`}
                        className={`${classes.img} ${index === 0 || index === 11 || index === 18 || index === 29 ? classes.large : ''}`}
                        onClick={() => handleImageClick(src)}
                        loading="lazy"
                    />
                ))}
            </div>

            {selectedImage && (
                <div className={classes.lightbox} onClick={handleClose}>
                    <img src={selectedImage} alt="Large preview" className={classes.lightboxImage} />
                    <button className={classes.closeButton} onClick={handleClose}>
                        ×
                    </button>
                </div>
            )}
        </div>
    );
};

export default Gallery;