import React, {useState} from 'react';
import textData from '../../text.json';
import classes from './Workshop.module.css';
import img1 from '../../assets/workshop/img1.JPG'; 
import img2 from '../../assets/workshop/img2.jpg'; 
import img11 from '../../assets/workshop/img11.JPG'; 
import img12 from '../../assets/workshop/img12.JPG'; 
import img13 from '../../assets/workshop/img13.JPG'; 
import img111 from '../../assets/workshop/img1111.jpg';
import img21 from '../../assets/workshop/img21.JPG';
import img22 from '../../assets/workshop/img22.JPG';
import img23 from '../../assets/workshop/img23.JPG';
import img24 from '../../assets/workshop/img24.JPG';
import img25 from '../../assets/workshop/img25.JPG';
import img26 from '../../assets/workshop/img26.JPG';
import img27 from '../../assets/workshop/img27.JPG';
import img28 from '../../assets/workshop/img28.JPG';
import img31 from '../../assets/workshop/img31.JPG';
import img32 from '../../assets/workshop/img32.JPG';
import img33 from '../../assets/workshop/img33.jpg';
import img34 from '../../assets/workshop/img34.jpg';
import ApplyButton from '../components/ApplyButton';

const WorkshopContent = () => {
    const [loaded, setLoaded] = useState(false);


    return (
        <section className={classes.workshop}>
            <div className={classes.titleContainer}>
                <p className={classes.workshopTitle}>Workshop</p>
            </div>
            <div className={classes.firstContainer}>
                <div className={classes.firstContainerText}>
                    <p className={classes.text}>{textData.workshop.para0}</p>
                    <p className={classes.text}>{textData.workshop.para3}</p>
                    <p className={classes.text}>{textData.workshop.para4}</p>
                    <b className={classes.text}>{textData.workshop.para41Title}</b>
                    <p className={classes.text}>{textData.workshop.para41}</p>
                </div>
                <div className={classes.firstContainerImage}>
                    <img src={img1} className={classes.img1} loading="lazy"/>
                    <img src={img2} className={classes.img11} loading="lazy" />
                </div>
            </div>
            <div className={classes.workshopDescriptionsContainer}>
                <div className={classes.subContainerTitle}>
                    <p className={classes.title}>{textData.workshop.DescriptionTitle}</p>
                </div>
                <div className={classes.subContainers}>
                    <div className={classes.subContainer1}>
                        <div className={classes.subContainer}>
                            <p className={classes.subtitle}>{textData.workshop.para17Title}</p>
                            <p className={classes.text2}>{textData.workshop.para17}</p>
                            <p className={classes.text2}>{textData.workshop.para18}</p>
                        </div>
                        <div className={classes.subContainer}>
                            <p className={classes.subtitle}>{textData.workshop.para19Title}</p>
                            <p className={classes.text2}>{textData.workshop.para19}</p>
                            <p className={classes.text2}>{textData.workshop.para20}</p>
                        </div>
                    </div>
                    <div className={classes.subContainer1}>
                        <div className={classes.subContainer}>
                            <p className={classes.subtitle}>{textData.workshop.para21Title}</p>
                            <p className={classes.text2}>{textData.workshop.para21}</p>
                            <p className={classes.text2}>{textData.workshop.para22}</p>
                        </div>
                        <div className={classes.subContainer}>
                            <p className={classes.subtitle}>{textData.workshop.para23Title}</p>
                            <p className={classes.text2}>{textData.workshop.para23}</p>
                            <p className={classes.text2}>{textData.workshop.para231}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.imageContainer}>
                <img src={img11} className={classes.img2} loading="lazy"/>
                <img src={img12} className={classes.img3} loading="lazy"/>
                <img src={img13} className={classes.img4} loading="lazy"/>
            </div>
            <div className={classes.priceTextContainer}>            
                <p className={classes.priceSubtitle}>{textData.workshop.priceTitle}</p>
                <p className={classes.priceText}>{textData.workshop.workshop}</p>
                <p className={classes.priceText}>{textData.workshop.workshop2}</p>
                <p className={classes.priceText}>{textData.workshop.workshop3}</p>
                <p className={classes.priceText}>{textData.workshop.workshop4}</p>
                <p className={classes.priceText}>{textData.workshop.para5}</p>
            </div>
            <div className={classes.workshopContentContainer}>
                <div className={classes.designElement}>
                    <div className={classes.workshopText}>            
                        <p className={classes.text3}>{textData.workshop.para10}</p>
                        <p className={classes.text3}>{textData.workshop.para11}</p>
                        <p className={classes.text3}>{textData.workshop.para12}</p>
                        <p className={classes.text3}>{textData.workshop.para13}</p>
                        <p className={classes.text3}>{textData.workshop.para14}<b>{textData.workshop.para14Bold}</b>{textData.workshop.para142}</p>
                        <p className={classes.text3}>{textData.workshop.plus}</p>
                        <p className={classes.text3}>{textData.workshop.data}</p>
                        <p className={classes.text4}>{textData.workshop.data1}</p>
                        <p className={classes.text4}>{textData.workshop.data2}</p>
                        <p className={classes.text4}>{textData.workshop.data3}</p>
                    </div>
                    <img src={img111} className={classes.img5} />
                </div>
            </div>
            <div className={classes.applyBox}>
                <p className={classes.buttonBoxText}>{textData.workshop.para15}</p>
                <p className={classes.buttonBoxText}><b>{textData.workshop.para16}</b></p>
                <div className={classes.buttonBox}>
                    <ApplyButton text="Jelentkezz Workshopra" />
                </div>
            </div>
            <p className={classes.galleryTitle}> Workshop képek</p>
            <div className={classes.imageContainer2}>
                <img src={img21} className={classes.images} loading="lazy"/>
                <img src={img22} className={classes.images} loading="lazy"/>
                <img src={img23} className={classes.images} loading="lazy"/>
                <img src={img24} className={classes.images} loading="lazy"/>
                <img src={img25} className={classes.images} loading="lazy"/>
                <img src={img26} className={classes.images} loading="lazy"/>
                <img src={img27} className={classes.images} loading="lazy"/>
                <img src={img28} className={classes.images} loading="lazy"/>
                <img src={img31} className={classes.images} loading="lazy"/>
                <img src={img32} className={classes.images} loading="lazy"/>
                <img src={img33} className={classes.images} loading="lazy"/>
                <img src={img34} className={classes.images} loading="lazy"/>
            </div>
        </section>
    );
};

export default WorkshopContent;