import React, { useState, useEffect } from 'react';
import Navigation from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import logo from "../../assets/logo.png";
import classes from "./Root.module.css";
import Footer from "../Footer/Footer";
import ScrollToTop from './ScrollToTop';

const Root = () => {

  return (
      <>
        <div className={classes.logoContainer}>
          <img src={logo} alt="Logo" className={classes.logoStatic} />
        </div>
        <Navigation />
        <main className={classes.container}>
          <Outlet />
        </main>
        <ScrollToTop />
        <Footer />
      </>
  );
};

export default Root;
