import React from 'react';
import classes from './Footer.module.css';
import facebookIcon from '../../assets/applyWorkshop/facebook-icon.png';
import instagramIcon from '../../assets/applyWorkshop/instagram.png';

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <div className={classes.footerUpper}>
                <div className={classes.footerSection}>
                    <p className={classes.footerLabel}>telefon</p>
                    <p className={classes.footerInfo}>+36706118590</p>
                </div>
                <div className={classes.footerSection}>
                    <p className={classes.footerLabel}>e-mail</p>
                    <p className={classes.footerInfo}>rkwalldesign@gmail.com</p>
                </div>
                <div className={classes.footerSection}>
                    <p className={classes.footerLabel}>social media</p>
                    <div className={classes.footerSocial}>
                        <a href="https://www.facebook.com/profile.php?id=61555170377700" target="_blank"
                        rel="noopener noreferrer">
                            <img src={facebookIcon} alt="Facebook" className={classes.link}/>
                        </a>
                        <a href="https://www.instagram.com/rkwallartdesign/?utm_source=qr" target="_blank"
                        rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className={classes.link}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className={classes.footerDown}>
                <p className={classes.contentText}>©2024 RK Wall Art Design. Minden jog fenntartva.</p>
            </div>
        </footer>

    );
};

export default Footer;
