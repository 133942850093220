import React from "react";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import HomePage from "./components/Home/Home";
import RootLayout from "./components/RootLayout/Root";
import ErrorPage from "./components/Error/Error";
import GalleryPage from "./components/Galleries/Gallery";
import WorkshopPage from './components/Workshop/WorkshopContent';
import ApplyWorkshopPage from "./components/ApplyWorkshop/ApplyWorkshop";

const router = createBrowserRouter([
    { path: '/',
        element: <RootLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            { path: '/', element: <HomePage/> },
            { path: '/workshop', element: <WorkshopPage/> },
            { path: '/gallery/', element: <GalleryPage/> },
            { path: '/apply', element: <ApplyWorkshopPage/> },
        ]},
])
function App() {

  return (
      <RouterProvider router={router}/>
  );
}
/* https://www.youtube.com/watch?v=utfRnLJTIsc */
export default App;
