import classes from './ContactForm.module.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import textData from '../../text.json';

const ContactForm = () => {
  const [inputValues, setInputValues] = useState({
      from_name: '',
      from_email: '',
      from_phoneNumber: ''
  });

  const [submissionMessage, setSubmissionMessage] = useState('');
  const form = useRef();

  const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValues(prev => ({
          ...prev,
          [name]: value
      }));
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      if (!inputValues.from_name || !inputValues.from_phoneNumber || !inputValues.from_email) {
          setSubmissionMessage('Kérjük, töltse ki az összes kötelező mezőt.');
          return;
      }

      emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID, 
        process.env.REACT_APP_TEMPLATE_ID, 
        form.current, 
        process.env.REACT_APP_PUBLIC_KEY,
      )
      .then((result) => {
            form.current.reset();
            setSubmissionMessage('Az üzenet sikeresen elküldve!');
      }, (error) => {
            console.error('Hiba:', error);
            setSubmissionMessage('Hiba történt az üzenet küldése közben.');
      });
  };

  return (
        <div className={classes.applyWorkshopContainer}>
            <div className={classes.titleContainer}>
                <p className={classes.title}>Ajánlatkérés/Jelentkezés workshopra</p>
            </div>
            <form className={classes.contactWorkshopForm} ref={form} onSubmit={handleSubmit}>
                <input
                    type="text"
                    className={classes.name}
                    placeholder="Név"
                    name='from_name'
                    onChange={handleChange} required
                />
                <input
                    type="number"
                    className={classes.phoneNumber}
                    placeholder="Telefon"
                    name='from_phoneNumber'
                    onChange={handleChange} required
                />
                <input
                    type="email"
                    className={classes.email}
                    placeholder="Email"
                    name='from_email'
                    onChange={handleChange} required
                />
                <textarea
                    name="message"
                    placeholder="Üzenet szövege"
                    className={classes.msg} required
                ></textarea>
                <button type="submit" className={classes.submitBtn}>Küldés</button>
                {submissionMessage && (
                    <div className={classes.submissionMessage}>{submissionMessage}</div>
                )}
            </form>
        </div>
  );
}

export default ContactForm;
